import React, { useEffect, useState } from "react";
import { Badge, Button, Col, FormControl, FormGroup, FormLabel, FormSelect, InputGroup, Row, Stack, Table } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import { Card, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import { CSVLink } from "react-csv";
import csvDownload from 'json-to-csv-export'

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import BarChart from "./charts/BarChart";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ReportPDF from "./ReportPDF";
import VerticalChart from "./charts/VerticalChart";
import PieChart from "./charts/PieChart";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
const ReportView = () => {
  const [arrName, setArrName] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const report = location.state;
  const [body, setBody] = useState([]);
  const [state, setState] = useState(location.state ? location.state : {});
  //console.log('state', state)
  const [activeTab, setActiveTab] = useState("Report");

  // state for modify query according to date given by user
  const [data, setData] = useState([]);
  const [Query, setQuery] = useState({});
  const [date, setDate] = useState();
  const [monthCalander, setMonthCalander] = useState(true);
  const [filteredColumn, setFilteredColumn] = useState("");
  const [filteredColumnList, setFilteredColumnList] = useState([]);

  const [filterValue, setFilterValue] = useState([]);

  // useEffect(() => {
  //   console.log("CSV DATA", reportDataForCSV);
  //   // setReportDataForCSV( {data: body,
  //   //   filename: state.name,
  //   //   delimiter: ',',
  //   //   headers: tempLabels});
  // }, [body]);

  useEffect(() => {
    fetchDataById()
  }, []);
  let tempLabels
  const fetchDataById = async () => {
    if (location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3) {
      state.id = location.pathname.split('/')[2];
    }
    //console.log('id', state.id)
    let queryResult = await SparkApi.fetchReportsById(state.id);

    setState(queryResult[0]);
    console.log(' id call', queryResult);
    console.log(' IS custom', queryResult[0].iscustom);
    if (queryResult && queryResult[0]?.iscustom) {
      // accessCustomData()
      accessRowData(queryResult[0]);
    } else {
      accessRowData(queryResult[0]);
    }
    setQuery(queryResult[0]);
    groupRowData(queryResult[0].groupbyquery?.query)

  }

  const fetchQueryData = async (sqlQuery) => {
    return await SparkApi.fetchQueryData(sqlQuery);
  }


  // ------ fetch reports data when component render ------
  const accessRowData = async (report) => {
    switch (report.apiname) {

      // Month wise lead report
      case "month_wise_lead_report": {
        // let localDate = true || date == "" ? ((new Date()).toDateString()) : date

        console.log({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true })
        // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:report.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      // All new Leads
      case "all_new_leads": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "craeteddate", date: localDate, isMonthWiseFilter: true })
        // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:report.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      // lead count by status
      case "lead_count_by_stages": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true })

        // ###--- according to date under process
        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });

        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      // Agent Wise Lead Report
      case "agent_wise_lead_report": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true })

        // --- according to date under process ---
        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true });

        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      // Lead Count By Agent
      case "lead_count_by_agent": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true })

        // ###--- according to date under process
        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true });

        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      // Day wise meeting
      case "day_wise_meeting_report": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "targetdate", date: localDate, isMonthWiseFilter: true })

        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });

        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "targetdate", date: null, isMonthWiseFilter: true });    
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      // Due amount 
      case "due_amount_report": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true })

        // ###--- according to date under process
        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true });

        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      // userLocation (Emp)
      case "user_location_history_employee": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true })

        // ###--- according to date under process
        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true });

        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });
        // console.log(result);
        // Date Issue fixed on frontend 13 DEC 2024
        const transformedResult = result.map(item => ({
          ...item,
          "Shift Start Date Time": item["Shift Start Date Time"] 
              ? moment(item["Shift Start Date Time"]).tz('Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') 
              : null,
          "Shift End Date Time": item["Shift End Date Time"] 
              ? moment(item["Shift End Date Time"]).tz('Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') 
              : null
        }));
      
        setData(transformedResult || []);
        setBody(transformedResult || []);

      }
        break;

      // userLocation (lead)
      case "user_location_history_leads": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true })

        // ###--- according to date under process
        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true });

        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });
        // console.log(result);
        // Date Issue fixed on frontend 13 DEC 2024
        const transformedResult = result.map(item => ({
          ...item,
          "Shift Start Date Time": item["Shift Start Date Time"] 
              ? moment(item["Shift Start Date Time"]).tz('Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') 
              : null,
          "Shift End Date Time": item["Shift End Date Time"] 
              ? moment(item["Shift End Date Time"]).tz('Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') 
              : null
        }));
      
        setData(transformedResult || []);
        setBody(transformedResult || []);

      }
        break;

      // Business
      case "business_report": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true })

        // ###--- according to date under process
        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true });

        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;
      // Business
      case "task_activity_report": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, filterColumn: "--", date: localDate, isMonthWiseFilter: true })

        // ###--- according to date under process
        const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: null, date: null, isMonthWiseFilter: true });

        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      case "product_wise_sales_report": {
        let localDate = true || date == "" ? ((new Date()).toDateString()) : date
        console.log({ apiName: report.apiname, date: localDate, isMonthWiseFilter: true })

        // ###--- according to date under process
        const result = await SparkApi.fetchReportData({ apiName: report.apiname, isMonthWiseFilter: true });

        // const result = await SparkApi.fetchReportData({ apiName: report.apiname, filterColumn: "--", date: null, isMonthWiseFilter: true });
        console.log(result)
        setData(result || []);
        setBody(result || []);
      }
        break;

      default: {
        console.log(report)
        const result = await fetchQueryData(report.query) || [];
        setData(result || []);
        setBody(result || []);
      }
        break;
    }
  };

  // ------ fetch reports data on date change ------
  useEffect(() => {
    console.log("Body => ", body);
    const dateWiseReport = async (e) => {
      // console.log(monthCalander);
      // console.log("Query", Query.apiname);
      // console.log("date", date);
      switch (Query.apiname) {

        case "month_wise_lead_report": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result)
          setData(result || []);
          setBody(result || []);
        }
          break;

        case "all_new_leads": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result)
          setData(result || []);
          setBody(result || []);
        }
          break;

        // lead count by status
        case "lead_count_by_stages": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result)
          setData(result || []);
          setBody(result || []);
        }
          break;

        // Agent Wise Lead Report
        case "agent_wise_lead_report": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result)
          setData(result || []);
          setBody(result || []);
        }
          break;

        // Lead Count By Agent
        case "lead_count_by_agent": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result)
          setData(result || []);
          setBody(result || []);
        }
          break;

        // Day wise meeting
        case "day_wise_meeting_report": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result)
          setData(result || []);
          setBody(result || []);
        }
          break;

        // Due amount 
        case "due_amount_report": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result)
          setData(result || []);
          setBody(result || []);
        }
          break;

        case "user_location_history_employee": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          // console.log(result);
          // Date Issue fixed on frontend 13 DEC 2024
          const transformedResult = result.map(item => ({
            ...item,
            "Shift Start Date Time": item["Shift Start Date Time"] 
                ? moment(item["Shift Start Date Time"]).tz('Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') 
                : null,
            "Shift End Date Time": item["Shift End Date Time"] 
                ? moment(item["Shift End Date Time"]).tz('Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') 
                : null
          }));
        
          setData(transformedResult || []);
          setBody(transformedResult || []);

        }
          break;

        case "user_location_history_leads": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          // console.log(result);
          // Date Issue fixed on frontend 13 DEC 2024
          const transformedResult = result.map(item => ({
            ...item,
            "Shift Start Date Time": item["Shift Start Date Time"] 
                ? moment(item["Shift Start Date Time"]).tz('Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') 
                : null,
            "Shift End Date Time": item["Shift End Date Time"] 
                ? moment(item["Shift End Date Time"]).tz('Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') 
                : null
          }));
        
          setData(transformedResult || []);
          setBody(transformedResult || []);

        }
          break;

        // Business
        case "business_report": {
          console.log({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterColumn: "craeteddate", date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result);
          setData(result || []);
          setBody(result || []);
        }
          break;

        case "task_activity_report":
          {
            console.log("TASK DATA",filterValue,monthCalander,date)
            // console.log({ apiName: Query.apiname,date: new Date(date).toDateString(), isMonthWiseFilter: true })
            // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
            if (!filterValue && date) {
              let result = [];
              if (monthCalander) {
                result = await SparkApi.fetchReportData({ apiName: Query.apiname, date: new Date(date).toDateString(), isMonthWiseFilter: true });
              } else {
                result = await SparkApi.fetchReportData({ apiName: Query.apiname, date: new Date(date).toDateString(), isMonthWiseFilter: false });
              }
              console.log(result);
              setData(result || []);
              setBody(result || []);
            }
            else if (filterValue && date) {
              let result = null;
              if (monthCalander) {
                result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterValue, date: new Date(date).toDateString(), isMonthWiseFilter: true });
              } else {
                result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterValue, date: new Date(date).toDateString(), isMonthWiseFilter: false });
              }
              console.log(result)
              setData(result || []);
              setBody(result || []);
            }
            else  if (filterValue){
              let result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterValue });
              console.log(result)
              setData(result || []);
              setBody(result || []);
            }
          }
          break;


        case "product_wise_sales_report": {
          console.log({ apiName: Query.apiname, date: new Date(date).toDateString(), isMonthWiseFilter: true })
          // console.log("month_wise_lead_report",await SparkApi.fetchReportData({apiName:Query.apiname,filterColumn:"craeteddate",date:localDate,isMonthWiseFilter:true}));
          let result = [];
          if (monthCalander) {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, date: new Date(date).toDateString(), isMonthWiseFilter: true });
          } else {
            result = await SparkApi.fetchReportData({ apiName: Query.apiname, date: new Date(date).toDateString(), isMonthWiseFilter: false });
          }
          console.log(result);
          setData(result || []);
          setBody(result || []);
        }
          break;

        default:
          console.log("Other report");
          break
      }
    };

    dateWiseReport();
  }, [filterValue, date]);

  // useEffect(() => {

  //   const init = async () => {
  //     if (Query.apiname === "task_activity_report") {
  //       if (filterValue && date) {
  //         let result = null;
  //         if (monthCalander) {
  //           result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterValue, date: new Date(date).toDateString(), isMonthWiseFilter: true });
  //         } else {
  //           result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterValue, date: new Date(date).toDateString(), isMonthWiseFilter: false });
  //         }
  //         console.log(result)
  //         setData(result || []);
  //         setBody(result || []);
  //       }
  //       else {
  //         let result = await SparkApi.fetchReportData({ apiName: Query.apiname, filterValue });
  //         console.log(result)
  //         setData(result || []);
  //         setBody(result || []);
  //       }
  //     }
  //   }
  //   init();
  // }, [filterValue, date])

  const [groupbyqueryresult, setgroupbyqueryresult] = useState([]);

  const groupRowData = async (groupquery) => {
    if (groupquery) {
      //console.log('groupquery', groupquery)
      let queryResult = await SparkApi.fetchGroupbyQueryData(groupquery);
      //console.log('queryResult', queryResult)
      //console.log('group qury result', queryResult)
      if (queryResult) {
        setgroupbyqueryresult(queryResult)
      } else {
        setgroupbyqueryresult([])
      }
    }
  }

  let header = Object.keys(body[0] || {}).map((value) => {
    // console.log(value)
    // if(value == "Lead Name")
    return {
      title: value,
      prop: value,
      isFilterable: true,

    };
  });

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const ReportDataCSV = {
    data: body,
    filename: state.name,
    delimiter: ',',
    headers: tempLabels
  }

  const pdfRecorder = () => {
    const pdfTable = document.getElementById("divToPrint");
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    const documentDefinition = {
      content: [html],
      pageBreakBefore: function (currentNode) {
        return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
      }
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).download(state.name ? state.name : "Report");

  };

  const editReport = () => {
    navigate(`/reportedit/${state.id}`, { state: state })
  };


  return (
    <Row className="g-0">
      <Row className="my-1">
        <Col lg={12}>
          <Link className="nav-link my-3" to="/reports">
            Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Reports</div>
          </Link>
          <nav className="navbar navbar-light" style={{ backgroundColor: "#fff" }}>
            <Col lg={10}>
              <h5 style={{ marginLeft: '10px' }}>Report Name : <Badge>{state.name}</Badge></h5>
            </Col>
            <Col className="d-flex mx-4 ">
              {state.iscustom && (
                <Button className='btn-sm mx-2' variant="danger" onClick={editReport}>
                  <i className="far fa-pen-to-square"></i>
                </Button>
              )}
              <Dropdown style={{ backgroundColor: '#82878f' }} >
                <Dropdown.Toggle disabled={!(body && Array.isArray(body) && body?.length !=0)}>
                  Download  <i class="fa fa-caret-down" aria-hidden="true"></i>

                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => csvDownload(ReportDataCSV)}>
                    <i class="fa-solid fa-file-csv" style={{ color: 'green' }}></i> CSV
                  </Dropdown.Item>
                  <Dropdown.Item onClick={pdfRecorder}>
                    <i className="far fa-file-pdf" style={{ color: 'red' }}></i> PDF
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>

          </nav>
        </Col>
      </Row>

      <Row lg={12}>

        <Col lg={12} className="my-3 ">

          <Card bg="light" text="light" >
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="Report"
                id="uncontrolled-tab-example"
                onSelect={handleSelect}
              >
                <Tab eventKey="Report" title="Report" />
                {state.iscustom && <Tab eventKey="Chart" title="Chart" />}
              </Tabs>
            </Card.Header>
            <Card.Body>
              {activeTab === "Report" && body ? (
                <Col lg={12} >
                  {/* Data Table */}
                  <DatatableWrapper
                    body={body}
                    headers={header}
                    paginationOptionsProps={{
                      initialState: {
                        rowsPerPage: 20,
                      },
                    }}

                  >
                    <Row className="d-flex gap-2 pb-3">
                      {
                        Query.apiname &&
                        <Col md={"auto"}>

                          <Stack direction="horizontal" gap={3} className="text-dark" >
                            {Query.apiname === "task_activity_report" &&
                              <FormGroup key={"filterValue"}>
                                <FormLabel>Filter Value</FormLabel>
                                <FormControl
                                  type="text"
                                  value={filterValue}
                                  onChange={(e) => { setFilterValue(e.target.value) }}
                                  placeholder="Enter value for filter"
                                />
                              </FormGroup>}
                            <FormGroup key={"fromGroup2"}>
                              <FormLabel>Filter Date</FormLabel>
                              <FormControl
                                type="date"
                                value={date}
                                onChange={(e) => { setDate(e.target.value) }}
                                onFocus={() => (setMonthCalander(false))}
                              />
                            </FormGroup>
                            <FormGroup className="d-flex flex-direction-column">
                              <FormLabel>Filter Month</FormLabel>
                              <DatePicker selected={date && date == '' ? null : date}
                                showIcon
                                onChange={(Date) => { Date && setDate(Date.toDateString()) }}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                className="form-control"
                                onFocus={() => (setMonthCalander(true))}
                              />
                            </FormGroup>
                          </Stack>

                        </Col>
                      }

                    </Row>
                    {/* <Stack direction="horizontal">
                        <Filter/>
                        <div className="d-flex align-item-center">
                          <PaginationOptions />
                        </div>
                          
                    </Stack> */}
                    <Table striped className="data-table" responsive="sm">
                      <TableHeader />
                      <TableBody />
                    </Table>
                    <Pagination />
                  </DatatableWrapper>
                </Col>
              ) : null}
              {activeTab === "Chart" && (
                <Col lg={12} className="text-center my-3">
                  {/* Chart */}
                  <Card>
                    <Card.Header>Group By REPORT</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div style={{ height: "350px", width: '600px', marginLeft: '20%' }} >
                          {state.charttype === 'barchart' && <BarChart data={groupbyqueryresult} />}
                          {state.charttype === 'vertical' && <VerticalChart data1={groupbyqueryresult} />}
                          {state.charttype === 'piechart' && <PieChart data2={groupbyqueryresult} />}
                        </div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="App container mt-5" style={{ display: 'none' }}>
        <div id="divToPrint">
          <ReportPDF header={header} data={body} reportname={state.name} />
        </div>
      </div>

      <Col lg={2}></Col>
    </Row>
  );
};
export default ReportView;