import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";


import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import authApi from "../api/authApi";
import jwt_decode from "jwt-decode";

const UserList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [leadname, setLeadName] = useState();
  const [userInfo, setUserInfo] = useState(jwt_decode(localStorage.getItem('token')));
  // const[contact,setContact] = useState(location.state ? location.state : {});
  const [lead, setLead] = useState();
  //////console.log('location.state ='+location.state)

  useEffect(() => {
    
    async function init() {
      // Added new api for fetch all users 18 Nov 2024
      const users = await SparkApi.fetchAllUsers();
      //console.log('users',users)
      let resultWithoutSuperAdmin = [];
      if (userInfo.userrole === 'ADMIN' || userInfo.userrole === 'USER') {
        resultWithoutSuperAdmin = users.filter((value, index, array) => value.userrole !== 'SUPER_ADMIN');
        setBody(resultWithoutSuperAdmin);
      } else {
        setBody(users);
      }
      if (users) {
        ////console.log("user data =>", users);
        setLead(users);
      } else {
        setBody([]);
        setLead([]);
      }
    }
    init();
  }, []);

  

  const onFilterType = (event) => {
    if (event.target.value === "") {
      setBody(lead);
    } else {
      setBody(
        lead.filter((data) => {
          if (
            (data.leadstatus || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };


  const editUser = (row) => {
    ////console.log("row", row);
    navigate(`/users/${row.row.id}/e`, { state: row.row });
  }
  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Name",
      prop: "username",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/users/" + row.id} state={row}>
          {/* <img src={`https://spark.indicrm.io/images/${userInfo.tenantcode}/users/${row.id}.thumbnail`} className="rounded-circle" style={{height: "30px", width: "30px", objectFit: "cover"}}/>   */}
          <span className="mx-2">{row.username}</span>
        </Link>
      ),
    },
    { title: "Role", prop: "userrole", isFilterable: true },
    { title: "User Type", prop: "usertype", isFilterable: true },
    { title: "Manager Name", prop: "managername", isFilterable: true },
    { title: "Phone", prop: "phone", isFilterable: true },
    { title: "Email", prop: "email", isFilterable: true },
    {
      title: "Active", prop: "isactive", isFilterable: true,
      isSortable:true,
      cell: (row) => (

        row.isactive === true ? <i class="fa-regular fa-square-check" style={{ fontSize: "1.3rem", marginLeft: "19px" }}></i> : <i class="fa-regular fa-square" style={{ fontSize: "1.3rem", marginLeft: "19px" }}></i>

      )
    },
    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <Button className="btn-sm mx-2 " onClick={() => editUser({ row })}>
          <i className="fa-regular fa-pen-to-square "></i>
        </Button>
      ),
    },

  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createUser = () => {
    navigate(`/users/e`);
  };

  return (
    <Row className="g-0">
     
      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
          sortProps={{initialState:{prop:"isactive",order:'desc'}}}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
              <PaginationOptions labels={labels} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary mx-2"
                onClick={() => createUser(true)}
              >
                New User
              </Button>
              {/* <Button className="btn-sm" variant="outline-primary" onClick={()=>location.state ? navigate(`/contacts/${contact.id}`) : navigate('/contacts/')}>Back to Contact</Button> */}
            </Col>
          </Row>
          {body.length > 0 ? (
            <Table striped className="data-table" responsive = "sm">
              <TableHeader />
              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};
export default UserList;
